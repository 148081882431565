import eventbusPlugin from '@/plugins/eventbus';
import { createApp } from 'vue';
import i18n from '~/config/i18n';
import { pinia } from '~/store';
import App from './App.vue';
import isLoggedInPlugin from '@/plugins/isLoggedIn';

const app = createApp(App);
app.use(pinia);
app.use(i18n);
app.use(eventbusPlugin);
app.use(isLoggedInPlugin);

app.mount('#globalmodule');
