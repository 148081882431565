import { apiTokenKey, whoamiKey } from "@/config/storageKeys";
import { storageWithExpiration } from "@/helpers/storageHelper";
import { useUserStore } from "@/store/user";
import Cookies from "js-cookie";
import { Plugin } from "vue";

const isLoggedInPlugin: Plugin = {
    install: () => {
        const storage = storageWithExpiration(localStorage);
        const hasToken = !!storage.getItem(apiTokenKey);

        if (!hasToken) {
            const userStore = useUserStore();
            userStore.$reset();

            Cookies.remove(whoamiKey);
            if ((window as any).mitt) {
                (window as any).mitt.emit("update-whoami", "");
            }
        }
    },
};

export default isLoggedInPlugin;
