<template>
    <div class="snackbar">
        <transition-group name="fade">
            <div class="snack" v-for="(message, index) in messages" :key="index">
                <BaseAlert :message="message.message" :type="message.type" :closable="true" :id="message.id" @close="close" />
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts" setup>
import { eventBusKey } from "@/models/Symbols/Eventbus";
import { SnackbarMessage, snackbarMessageKey } from "@/models/Symbols/Snackbar";
import { inject, ref } from "vue";
import BaseAlert from "./BaseAlert.vue";

const messages = ref<SnackbarMessage[]>([]);

const close = (id: string) => (messages.value = messages.value.filter((v) => v.id !== id));

const bus = inject(eventBusKey);

bus?.on(snackbarMessageKey, (event) => {
    const e = event as SnackbarMessage;
    //Avoid duplicate errors (if two api calls fail simultaneously for example)
    if (!messages.value.find((m) => m.message === e.message)) {
        messages.value.push(e);
        setTimeout(() => {
            close(e.id);
        }, 3000);
    }
});
</script>

<style lang="scss" scoped>
@import "~/styles/transitions";
.snackbar {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 290px;
    z-index: 10002;

    @include media-breakpoint-up(sm) {
        width: 450px;
    }

    .snack {
        margin-bottom: $space-md;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        border-radius: 0.5rem;
        background-color: $c-white;
    }

    :deep(.alert) {
        position: relative;
        margin: 0 !important;
    }
}
</style>
