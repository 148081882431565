<template>
    <teleport to="body">
        <transition name="slide" appear mode="out-in">
            <div class="dialog-overlay" v-if="show && data" @click.self="overlayClicked">
                <div class="dialog" :class="`dialog--${className}`">
                    <SvgIcon class="dialog__icon" :icon="`booking/${iconType}`" />
                    <div class="dialog__content">
                        <header>
                            <h4>{{ data.title }}</h4>
                        </header>
                        <p v-html="data.message"></p>
                        <footer>
                            <BaseButton ref="closeBtnRef" v-if="data.no" @click="show = false" :class="'btn--outline'">{{ data.no }}</BaseButton>
                            <BaseButton @click.stop="callBack">{{ data.yes }}</BaseButton>
                        </footer>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import useDialog from "@/composables/useDialog";
import { DialogType } from "@/models/Symbols/Dialog";
import { computed, watch, nextTick, ref } from "vue";

const { show, data } = useDialog();

const overlayClicked = () => {
    if (data.value?.type === DialogType.redirect) {
        callBack();
    } else {
        show.value = !show.value;
    }
};

const callBack = () => {
    if (data.value?.callback) {
        data.value.callback();
    }
    show.value = false;
};

const className = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
            return "warning";
        case DialogType.danger:
            return "danger";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const iconType = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
        case DialogType.danger:
            return "alert";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const closeBtnRef = ref();

let previousElement: unknown;

watch(show, (current) => {
    if (current) {
        previousElement = document.activeElement;
        nextTick(() => {
            if(closeBtnRef.value){
                closeBtnRef.value.$el.focus();
            }
        });
    } else if (previousElement) {
        (previousElement as HTMLElement).focus();
    }
});
</script>

<style lang="scss" scoped>
@import "~/styles/transitions.scss";
@import "~/styles/icons.scss";

.dialog {
    display: flex;
    gap: $space-lg;
    margin: 0 15px;
    padding: 1.5rem;
    width: 100%;
    max-width: 420px;
    background-color: $c-white;
    border-radius: $br-input;

    &__icon {
        flex-shrink: 0;
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $space-md;
        width: 100%;
    }

    header {
        h4 {
            font-size: $fs-20;
        }
    }

    footer {
        margin-top: $space-md;
        display: flex;
        justify-content: flex-end;
        gap: $space-md;

        :deep(.btn) {
            min-width: 80px;
        }
    }

    &--info {
        .dialog__icon {
            color: $c-blue-dark;
            background-color: $c-white-100;
        }
    }

    &--warning {
        .dialog__icon {
            color: $c-blue-dark;
        }
    }

    &--danger {
        .dialog__icon {
            color: $c-red-100;
        }
    }

    &-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($c-black-100, 35%);
        z-index: 10001;
    }
}
</style>
